<template>
  <div class="pa-4" style="max-width: 1000px">
    <v-row>
      <v-col cols="12" md="9">
        <h2>
          EisbaumTabelle | Tierkrankenversicherungen mit OP Versicherung für
          Hunde
        </h2>
        <ul class="my-4">
          <li>
            Leistungsstarke Tierkrankenversicherungen inklusive OP Versicherung
            Hund
          </li>
          <li>
            Diese Tier-Krankenversicherungen leisten zu 100% für Katzen- und
            Hunde OPs
          </li>
          <li>
            Deshalb reichen die Leistungen einer Tier OP Versicherung nicht aus
          </li>
        </ul>

        <h3>
          5 Hunde-Krankenversicherungen die zu 100 % für Hunde OPs leisten
        </h3>
        <v-simple-table class="my-2">
          <thead>
            <tr style="vertical-align:top;">
              <th class="text-left" style="min-width:120px;">Tarif</th>
              <th class="text-left">Realwert</th>
              <th class="text-left">
                Erstattung für Operationen<br />
                des Hundes (in %)
              </th>
              <th class="text-left">
                Hunde OPs<br />
                pro Jahr (in €)
              </th>
              <th class="text-left">mtl. Beitrag</th>
            </tr>
            <tr
              style="vertical-align: top"
              v-for="item in hunde"
              :key="item.tier_id"
            >
              <td>
                <a
                  ><router-link
                    :to="`/Krankenversicherung_Hund/${item.tier_url}`"
                    >{{ item.tier_title }}</router-link
                  >
                </a>
              </td>
              <td>{{ item.tier_wert }} %</td>
              <td>{{ item.tier_op_erstattung_zahl }}</td>
              <td>{{ item.tier_op_erstattung_max_jahr }}</td>
              <td>
                <b>{{ item.preis }} €</b>
              </td>
            </tr>
          </thead>
          <tbody></tbody>
        </v-simple-table>
        <br /><br />
        <h3>
          5 Katzen-Krankenversicherungen die zu 100 % für Katzen OPs leisten
        </h3>
        <v-simple-table class="my-2">
          <thead>
            <tr style="vertical-align:top;">
              <th class="text-left" style="min-width:120px;">Tarif</th>
              <th class="text-left">Realwert</th>
              <th class="text-left">
                Erstattung für Operationen<br />
                de Katze (in %)
              </th>
              <th class="text-left">
                Katzen OPs<br />
                pro Jahr (in €)
              </th>
              <th class="text-left">mtl. Beitrag</th>
            </tr>
            <tr
              style="vertical-align: top"
              v-for="item in katzen"
              :key="item.tier_id"
            >
              <td>
                <a
                  ><router-link
                    :to="`/Krankenversicherung_Hund/${item.tier_url}`"
                    >{{ item.tier_title }}</router-link
                  >
                </a>
              </td>
              <td>{{ item.tier_wert_katze }} %</td>
              <td>{{ item.tier_op_erstattung_zahl_katze }}</td>
              <td>{{ item.tier_op_erstattung_max_jahr_katze }}</td>
              <td>
                <b>{{ item.preis }} €</b>
              </td>
            </tr>
          </thead>
          <tbody></tbody>
        </v-simple-table>
        <br />
        <h3>OP Versicherungen für Hunde - Der Kompromiss am falschen Ende</h3>
        Oft realisieren Tierhalter erst nach der Anschaffung ihres Tieres, dass
        die Tierhaltung zeitaufwendig und kostspielig ist. Der Hund braucht
        Futter, einen warmen Platz zum Schlafen und weiteres Equipment, um ihn
        zu beschäftigen. Das ist für die meisten Tierbesitzer jede Menge Geld,
        das sie gleich am Anfang für ihr Tier ausgeben müssen. Hinzu kommen die
        Beiträge für Tierversicherungen. Hier gehen besonders Hundebesitzer dann
        oft einen falschen Kompromiss ein. Sie schließen statt einer
        leistungsstarken Hundekrankenversicherung, die sowohl für
        Tier-Operationen wie auch für Tier-Behandlungen und Vorsorge leistet,
        eine OP Versicherung Hund ab. Der weit verbreitete Glaube ist, dass man
        so den größten Teil der tiermedizinischen Kosten decken kann. Doch
        Vorsicht! Wenn das Tier eine chronische Krankheit entwickelt, wie
        beispielsweise Diabetes oder eine Niereninsuffizienz, kann das zu
        monatlichen Kosten zwischen 50 € und 200 € führen. Um eine umfassende
        veterinärmedizinische Versorgung zu gewährleisten, empfehlen wir deshalb
        den Abschluss einer leistungsstarken Tierkrankenversicherung inklusive
        OP Versicherung für Hunde. Diese Krankenversicherungen für Hunde leisten
        sowohl für OPs, als auch für Behandlungen und Vorsorge Ihres Tieres.
        <br /><br />
        <h3>4 Grenzen einer OP Versicherung für Hunde</h3>
        Eine reine Hunde OP Versicherung leistet, wie der Name schon sagt, nur
        für Kosten, die in unmittelbarem Zusammenhang mit der Operation eines
        Tieres stehen.
        <br /><br />
        Für diese Bereiche leistet eine OP Versicherung für Hunde und Katzen
        nicht:
        <ol>
          <li>
            <b>Diagnostische Maßnahmen</b>, um die Krankheit eindeutig zu
            diagnostizieren. Oft sind mehrere Tierarztbesuche und Untersuchungen
            notwendig, um eine eindeutige Diagnose zu stellen.
          </li>
          <li>
            <b>Vorsorge-Maßnahmen</b>, wie z.B. Impfungen, Wurmkuren, etc., um
            Krankheiten vorzubeugen und die Tier-Gesundheit auch in Zukunft zu
            gewährleisten.
          </li>
          <li>
            <b>Chronische Krankheiten</b>, wie Diabetes oder Niereninsuffizienz,
            die nicht mit einem chirurgischen Eingriff behandelt werden können.
          </li>
          <li>
            <b>Behandlungen</b>, die nach einer Operation notwendig sind, wie
            zum Beispiel eine Physiotherapie, um die Mobilität des Hundes wieder
            vollständig herzustellen.
          </li>
        </ol>
        <br />
        <b>Fazit:</b> Um auch langfristig Geld zu sparen und eine umfassende
        Gesundheitsversorgung (inklusive Vorsorge) für Ihr Tier zu
        gewährleisten, empfehlen wir den Abschluss einer leistungsstarken
        Krankenversicherung für Ihr Tier. <br /><br />

        <h3>
          Chronische Tierkrankheiten, die nicht mit einer Operation des Tieres
          geheilt werden können.
        </h3>
        Tiere können im Laufe ihres Lebens viele chronische Erkrankungen
        entwickeln, die nicht mit einer Tier-OP behandelt werden können. Diese
        Krankheiten führen speziell in der Diagnosephase zu hohen Kosten und
        machen häufige Tierarztbesuche notwendig. Auch im weiteren Verlauf der
        Behandlung ist mit regelmäßigen monatlichen Ausgaben zu rechnen.
        <br /><br />
        <b>Diabetes bei Hunde und Katzen Behandlung:</b><br />
        Überwachung des Blutzuckerspiegel, Insulin-Injektionen und spezielle
        Ernährung Kosten: ca. 50 € - 150 € pro Monat (die mtl. Kosten verringern
        sich sobald der Blutzuckerspiegel gut eingestellt ist) <br /><br />
        <b> Niereninsuffizienz speziell bei älteren Tieren Behandlung:</b><br />
        Verabreichung von Medikamenten, Infusionen und speziellen Diäten Kosten:
        ca. 50 € - 200 € pro Monat (je nach Schwere der Krankheit) <br /><br />
        <b>Alle Arten von Allergien bei Hunden und Katzen</b><br />
        (z. B. Gräser- und Pollenallergie, Hausstauballergie,
        Futtermittelallergie, etc.) Behandlung: Allergietest, Immuntherapie,
        spezielles Futter und Medikamente Kosten: ca. 30 € - 100 € pro Monat
        (ist die Allergie diagnostiziert und die Behandlung festgelegt,
        reduzieren sich die mtl. Kosten) <br /><br />
        <b>Schilddrüsenunterfunktion speziell bei älteren Hunden Behandlung:</b
        ><br />
        lebenslange Hormonersatztherapie Kosten: ca. 30 € - 80 € pro Monat (die
        Diagnose kann sehr kostenintensiv sein)
        <br /><br />
        <b>Fazit:</b> Entwickelt ein Hund oder eine Katze eine chronische
        Krankheit, ist mit lebenslangen monatlichen Kosten zu rechnen, für die
        keine OP Versicherung Hund bzw. Katze leistet. Deshalb macht es Sinn,
        beide tiermedizinische Leistungsbereiche (Tierbehandlungen und
        Tieroperationen) mit einer Tierkrankenversicherung abzusichern.
        <br /><br />
        <h3>
          Viele Tieroperationen führen zu einer anschließenden Behandlungen des
          Tieres
        </h3>
        Die Behandlung eines Hundes (oder Katze) endet meistens nicht mit der
        erfolgreichen Operation. Das operierte Tier muss weiterhin untersucht
        und bei der Genesung unterstützt und begleitet werden. Auch hier haben
        Tier-OP Versicherungen ihre Grenzen. OP Versicherungen für Hunde leisten
        entweder gar nicht oder nur begrenzt und nur für einen bestimmten
        Zeitraum nach der Operation des Hundes für nachgelagerte Behandlungen
        oder Untersuchungen des Tieres. So führen Operationen an Knochen oder
        Gelenken zu einer nachgelagerten Physiotherapie des Tieres, um die
        Beweglichkeit des Hundes / der Katze wiederherzustellen. Diese
        Physiotherapie für Hunde nach einer OP kann oft mehrere Wochen oder
        sogar Monate in Anspruch nehmen. In manchen Fällen muss eine
        Schmerzbehandlung des Hundes angeschlossen werden. Operationen des
        Magens oder des Verdauungstraktes machen vorübergehende bzw. lebenslange
        Diäten oder die Einnahme von Medikamenten notwendig.
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <menue-download />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import MenueDownload from "./../base/helper/MenueDownload";
  import baseMixin from "../mixins/baseMixin";

  export default {
    name: "index-op",
    components: { MenueDownload },
    mixins: [baseMixin],
    data: function() {
      return {
        hunde: false,
        katzen: false,
      };
    },
    created() {
      this.setTitle();
      this.saveReferrer();
      this.getHunde();
    },

    methods: {
      saveReferrer() {
        var string = document.referrer,
          substring = ".google";
        var isGoogle = string.indexOf(substring) !== -1;
        if (isGoogle) {
          this.updateSeo.seo_title = document.title;
          this.$store.dispatch("saveReferrer", this.updateSeo);
        }
      },

      setTitle() {
        this.$log.debug("title");
        document.title =
          "Günstige Tierkrankenversicherungen mit OP Versicherung für Hunde";
        let metadesc =
          "Diese Hunde Krankenversicherungen leisten 100 % für Tier Operationen, nachgelagerte Behandlungen und chronische Krankheiten Ihres Hundes.";
        this.saveReferrer();
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metadesc);
      },
      getHunde() {
        let select = {};
        select.type = "hunde_op";
        select.preise = "ja";
        select.number = "array";

        this.$store
          .dispatch("getTarife", select)
          .then((resp) => {
            if (resp.data.tarife) {
              this.hunde = resp.data.tarife;
              this.getKatze();
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      getKatze() {
        let select = {};
        select.type = "katze_op";
        select.preise = "ja";
        select.number = "array";

        this.$store
          .dispatch("getTarife", select)
          .then((resp) => {
            if (resp.data.tarife) {
              this.katzen = resp.data.tarife;
              document.dispatchEvent(new Event("x-app-rendered"));
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
  };
</script>
