<template>
  <div>
    <v-card class="mx-auto my-8 pa-4" max-width="344" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <span class="caption">Für Tierärzte</span>
          <h3>Download Eisbaum-Tabelle 2024</h3>
        </v-list-item-content>
        <a href="/images/pdf/tabelle.pdf" target="_blank">
          <v-list-item-avatar tile size="80"
            ><img src="/images/pdf/picto.png" />
          </v-list-item-avatar>
        </a>
      </v-list-item>
      <v-btn
        to="/images/pdf/tabelle.pdf"
        target="_blank"
        density="compact"
        outlined
        class="nocaps mt-2 borderbtn"
        ><v-icon class="redtext mr-2">pets</v-icon> EisbaumTabelle | Hund</v-btn
      >

      <v-btn
        to="/images/pdf/Eisbaumtabelle_katze.pdf"
        target="_blank"
        density="compact"
        outlined
        class="nocaps mt-2 borderbtn"
        ><v-icon class="katzetext mr-2">pets</v-icon> EisbaumTabelle |
        Katze</v-btn
      >
    </v-card>

    <!-- <v-card class="mx-auto my-8" max-width="344" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <span class="caption">In der Presse</span>
          <h3>Hilfe bei der Qual der Wahl</h3>

          <span class="caption">erschienen 15.2.2022</span>
        </v-list-item-content>
        <a
          href="/images/pdf/tier_krankenversicherung_artikel.pdf"
          target="_blank"
        >
          <v-list-item-avatar tile size="80"
            ><img src="/images/vetimmpulse.jpg" />
          </v-list-item-avatar>
        </a>
      </v-list-item>

      <v-card-actions class="greyground py-1"
        ><a
          href="/images/pdf/tier_krankenversicherung_artikel.pdf"
          target="_blank"
          class="greytext"
          >Jetzt Download starten</a
        ></v-card-actions
      >
    </v-card> -->

    <!-- <v-card class="mx-auto my-8" max-width="344" outlined>
      <iframe
        width="224"
        src="https://www.youtube.com/embed/ZJ-_27uCms0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </v-card> -->
  </div>
</template>

<script>
  export default {
    name: "menue-download",
    components: {},

    data: function() {
      return {};
    },
    created() {},

    methods: {},
  };
</script>
